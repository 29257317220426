export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            this.initHomeServices()
        }
    }

    initHomeServices() {
        if ($('body').hasClass('home') && !IRISCollectionCustomer.isEcomodeActive) {
            if (!('ontouchstart' in window) && window.matchMedia('(min-width: 1000.1px)').matches) {
                import(
                    '@scripts/services/smoothScroll.service' /* webpackChunkName: "scripts/smoothScroll.service" */
                ).then(({ default: SmoothScroll }) => {
                    new SmoothScroll()
                })
            }

            import(
                '@scripts/services/homeSliders.service' /* webpackChunkName: "scripts/homeSliders.service" */
            ).then(({ default: HomeSlidersService }) => {
                new HomeSlidersService()
            })

            if (!('ontouchstart' in window)) {
                import(
                    '@scripts/services/homeBanner.service' /* webpackChunkName: "scripts/homeBanner.service" */
                ).then(({ default: HomeBannerService }) => {
                    new HomeBannerService()
                })
            }
        }
    }
}
